import Cyclone from '@ubidreams/cyclone'
import React from 'react'
import {
  SyncOutlined,
  TeamOutlined,
  TagOutlined,
  BulbOutlined,
  HomeOutlined,
  CheckOutlined,
  CrownOutlined,
  GlobalOutlined,
  BarcodeOutlined,
  DropboxOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  MessageOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons'

// Redux and Saga
import rootReducer from '../redux'
import rootSaga from '../saga'

// Components
import Images from '../assets/images'

// I18n and Less
import './App.less'
import './App.i18n'

function App() {
  const roles = {
    UBIADMIN: 0,
    SUPERADMIN: 10,
    THERMOFORMING: 20,
    PSE: 21
  }
  // Item properties
  const routesProps = {
    order: [
      'orders',
      'orders/waiting',
      'orders/treated',
      'articles',
      'articles/lids',
      'articles/boxes',
      'clients',
      'adv',
      'adv/thermoforming',
      'adv/pse',
      'adv-me',
      'messages',
      'brands',
      'settings'
    ],
    items: {
      adv: {
        gender: 'male',
        crumbDisabled: true,
        icon: <CrownOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN]
      },
      'adv/thermoforming': {
        hidden: true,
        gender: 'male',
        icon: <CrownOutlined />
      },
      'adv/pse': {
        hidden: true,
        gender: 'male',
        icon: <CrownOutlined />
      },
      'adv-me': {
        gender: 'male',
        icon: <CrownOutlined />,
        roles: [roles.PSE, roles.THERMOFORMING]
      },
      articles: {
        gender: 'male',
        icon: <BulbOutlined />
      },
      'articles/pse': {
        gender: 'male',
        icon: <GlobalOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.PSE]
      },
      'articles/thermoforming': {
        gender: 'male',
        icon: <GlobalOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.THERMOFORMING]
      },
      'articles/lids': {
        gender: 'male',
        hidden: true,
        icon: <BarcodeOutlined />
      },
      'articles/boxes': {
        gender: 'female',
        hidden: true,
        icon: <DropboxOutlined />
      },
      orders: {
        gender: 'male',
        icon: <ShoppingCartOutlined />
      },
      'orders/waiting': {
        hidden: true,
        gender: 'male',
        icon: <SyncOutlined />
      },
      'orders/treated': {
        hidden: true,
        gender: 'male',
        icon: <CheckOutlined />
      },
      clients: {
        gender: 'male',
        icon: <TeamOutlined />
      },
      warehouses: {
        hidden: true,
        gender: 'male',
        icon: <EnvironmentOutlined />
      },
      messages: {
        gender: 'female',
        icon: <MessageOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN]
      },
      brands: {
        gender: 'female',
        icon: <TagOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN]
      },
      settings: {
        gender: 'male',
        icon: <SettingOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN]
      },
      'private/articles': {
        hidden: true,
        icon: <BulbOutlined />
      },
      'private/universes': {
        gender: 'male',
        hidden: true,
        icon: <GlobalOutlined />
      },
      'private/universes/pse': {
        gender: 'male',
        hidden: true,
        icon: <GlobalOutlined />
      },
      'private/universes/thermoforming': {
        gender: 'male',
        hidden: true,
        icon: <GlobalOutlined />
      },
      'private/warehouses': {
        gender: 'male',
        hidden: true,
        icon: <HomeOutlined />
      },
      'private/warehouses/pse': {
        gender: 'male',
        hidden: true,
        icon: <HomeOutlined />
      },
      'private/warehouses/thermoforming': {
        gender: 'male',
        hidden: true,
        icon: <HomeOutlined />
      }
    }
  }
  const headerProps = {}
  const footerProps = {}

  // Cyclone config
  const config = {
    version: process.env.REACT_APP_VERSION,
    app: 'BACK_OFFICE',
    landingPage: 'orders',
    api: process.env.REACT_APP_API,
    logo: Images.icon,
    login_field_name: 'login',
    // login_check: false,
    api_secret_key: process.env.REACT_APP_API_SECRET_KEY,
    firebase: {
      apiKey: 'AIzaSyBzniGfYdbetnMPKWLD8v4ROlhaHgPrnvk',
      authDomain: 'knauf-4f9fe.firebaseapp.com',
      projectId: 'knauf-4f9fe',
      storageBucket: 'knauf-4f9fe.appspot.com',
      messagingSenderId: '906012725983',
      appId: '1:906012725983:web:f8896554c9615087e3cf6a',
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
    },
    goBackOnCreateOrUpdate: true,
    useSessions: true,
    customReactComponents: {
      WarehouseArticlesSelect: require('../custom/WarehouseArticlesSelect')
        .default
    }
  }

  return (
    <Cyclone
      config={config}
      routesProps={routesProps}
      headerProps={headerProps}
      footerProps={footerProps}
      appReducer={rootReducer}
      appSaga={rootSaga}
    />
  )
}

export default App
