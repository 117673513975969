import { put, delay } from 'redux-saga/effects'

// Actions
import StartupActions from '../redux/reducers/startupReducer'

/**
 * Prepare Cyclone
 * @param action
 */
export function* startup(action) {
  yield delay(500)

  // Preparation done
  yield put(StartupActions.startupDone())
}

/**
 * Register an API in Cyclone
 * @param action
 */
export function* startupDone(action) {
}
