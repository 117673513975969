// external libs
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { components } from '@ubidreams/cyclone'
import { InputNumber, Tooltip } from 'antd'
import { orange } from '@ant-design/colors'
import {
  includes,
  get,
  mapValues,
  keyBy,
  isNil,
  values,
  omitBy,
  isEmpty,
  isString
} from 'lodash'
import WarningFilled from '@ant-design/icons/lib/icons/WarningFilled'

// components
const { Table } = components

export default function WarehouseArticlesSelect({
  options,
  fieldsDescriptions,
  value,
  onChange
}) {
  // state
  const [total, setTotal] = useState(null)
  const [quantityByArticle, setQuantityByArticle] = useState({})
  const [selectedRows, setSelectedRows] = useState([])

  // ref
  const userIsUpdating = useRef(false)

  // selection has changed
  const handleSelectionChange = useCallback(
    (selectedRows) => {
      userIsUpdating.current = true
      setSelectedRows(selectedRows)
    },
    [value, options]
  )

  // update quantity for article (function is debounced)
  const updateQuantity = useRef((number, articleId) => {
    userIsUpdating.current = true
    setQuantityByArticle((prevState) => ({
      ...prevState,
      [articleId]: number
    }))
  })

  const triggerChange = useCallback(() => {
    onChange(
      options
        .filter((option) => includes(selectedRows, option.id))
        .map((option) => {
          let quantityForCurrentArticle = quantityByArticle[option.id]

          if (
            isNil(quantityForCurrentArticle) ||
            (isString(quantityForCurrentArticle) &&
              isEmpty(quantityForCurrentArticle))
          ) {
            quantityForCurrentArticle = -1
          }

          return `${option.id}:${quantityForCurrentArticle}`
        })
    )
  }, [selectedRows, quantityByArticle, options])

  // selected rows or quantity for article has changed
  useEffect(() => {
    if (userIsUpdating.current) {
      userIsUpdating.current = false
      triggerChange()
    }
  }, [selectedRows, quantityByArticle, userIsUpdating])

  // on mount
  useEffect(() => {
    if (value) {
      setQuantityByArticle(mapValues(keyBy(value, 'id'), 'minQuantity'))
      setSelectedRows(value.map((item) => item.id))
    }
  }, [])

  // input rendering
  const renderInput = useCallback(
    (text, record, index) => {
      return (
        <QuantityInput
          key={index}
          article={record}
          disabled={!includes(selectedRows, record.id)}
          value={get(quantityByArticle, record.id)}
          onChange={(number) => updateQuantity.current(number, record.id)}
        />
      )
    },
    [selectedRows, quantityByArticle, updateQuantity]
  )

  return (
    <Table
      namespace='domains'
      dataSource={options}
      columnsDescriptions={{
        ...fieldsDescriptions,
        minQuantity: {
          ...fieldsDescriptions.minQuantity,
          isFilterDisabled: true,
          render: renderInput
        }
      }}
      showSearch={false}
      rowSelectable
      selection={selectedRows}
      onSelectionChange={handleSelectionChange}
      onClick={() => {}}
      onChange={(pagination, filters, sorter, extra) => {
        if (values(omitBy(filters, isNil)).length > 0) {
          setTotal(extra.currentDataSource.length)
        } else {
          setTotal(null)
        }
      }}
      total={total}
    />
  )
}

function QuantityInput({
  index,
  disabled,
  onChange,
  value: initialValue,
  article
}) {
  const [value, setValue] = useState(initialValue)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputNumber
        style={{ flex: 1 }}
        key={index}
        disabled={disabled}
        size='small'
        placeholder='Quantité'
        value={value}
        onChange={(number) => {
          setValue(number)
        }}
        onBlur={() => onChange(value)}
      />
      {article.universe === 'THERMOFORMING' && (
        <Tooltip title={`Pièces par palette : ${article.quantity_per_package}`}>
          <WarningFilled style={{ marginLeft: '4px', color: orange[5] }} />
        </Tooltip>
      )}
    </div>
  )
}
