import i18next from 'i18next'
import { defaultTo } from 'lodash'

// Get values from env
const REACT_APP_DESCRIPTION =
  process.env.REACT_APP_DESCRIPTION === ''
    ? null
    : process.env.REACT_APP_DESCRIPTION
const REACT_APP_NAME =
  process.env.REACT_APP_NAME === '' ? null : process.env.REACT_APP_NAME

// en
i18next.addResourceBundle('en', 'Common', {
  app: {
    name: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    title: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    description: defaultTo(REACT_APP_DESCRIPTION, 'Automatically generated')
  }
})

i18next.addResourceBundle('en', 'Page', {})

// fr
i18next.addResourceBundle('fr', 'Common', {
  app: {
    name: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    title: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    description: defaultTo(
      REACT_APP_DESCRIPTION,
      'Application générée automatiquement'
    )
  }
})

i18next.addResourceBundle('fr', 'Page', {
  orders: {
    short: 'réapprovisionnements',
    title: 'réapprovisionnements',
    plural: 'réapprovisionnements',
    singular: 'réapprovisionnement'
  },
  settings: {
    short: 'paramètres',
    title: 'paramètres',
    plural: 'paramètres',
    singular: 'paramètre'
  },

  articles: {
    short: 'articles',
    title: 'articles',
    plural: 'articles',
    singular: 'article',

    lids: {
      short: 'couvercles',
      title: 'couvercles',
      plural: 'couvercles',
      singular: 'couvercle'
    },

    boxes: {
      short: 'caisses',
      title: 'caisses',
      plural: 'caisses',
      singular: 'caisse'
    },

    thermoforming: {
      short: 'thermoformage',
      title: 'articles thermoformage',
      plural: 'articles thermoformage',
      singular: 'article thermoformage'
    },

    pse: {
      short: 'PSE',
      title: 'articles PSE',
      plural: 'articles PSE',
      singular: 'article PSE'
    }
  },

  clients: {
    short: 'clients',
    title: 'clients',
    plural: 'clients',
    singular: 'client'
  },

  warehouses: {
    short: 'lieux de livraison',
    title: 'lieux de livraison',
    plural: 'lieux de livraison',
    singular: 'lieu de livraison'
  },

  adv: {
    short: 'ADV',
    title: 'ADV',
    plural: 'ADV',
    singular: 'ADV',

    thermoforming: {
      short: 'thermoformage',
      title: 'ADV thermoformage',
      plural: 'ADV thermoformage',
      singular: 'ADV thermoformage'
    },
    pse: {
      short: 'PSE',
      title: 'ADV PSE',
      plural: 'ADV PSE',
      singular: 'ADV PSE'
    }
  },

  'adv-me': {
    short: 'Ma fiche ADV',
    title: 'Ma fiche ADV'
  },

  brands: {
    short: 'marques',
    title: 'marques',
    plural: 'marques',
    singular: 'marque'
  },

  messages: {
    short: 'notifications',
    title: 'notifications',
    plural: 'notifications',
    singular: 'notification'
  }
})
