import { takeLatest, all } from 'redux-saga/effects'

/* ------------- Types ------------- */

import { StartupTypes } from '../redux/reducers/startupReducer'

/* ------------- saga ------------- */

import { startup, startupDone } from './startupSagas'

/* ------------- Connect Types To saga ------------- */

export default function* root() {
  yield all([
    // Start the application
    takeLatest(StartupTypes.STARTUP, startup),

    // Application started
    takeLatest(StartupTypes.STARTUP_DONE, startupDone)
  ])
}
